import styled, { css } from 'styled-components';
import { DropdownButton, DropdownItem } from 'react-bootstrap';
import Datetime from 'react-datetime';

import { CustomInput } from '../InputGoals/styles';

const RegularFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Bold';


const Layout = styled.div`
  padding: 30px 25px 20px 30px;
  width: 100%;
  float: left;
  min-height: ${({minHeight}) => minHeight ? minHeight : '650px'};
  font-family: ${RegularFont};
  margin-bottom: ${({marginBottom}) => marginBottom ? marginBottom : '100px'};
  background-color: ${({backgroundColor}) => backgroundColor ? backgroundColor : 'auto'}

  @media (max-width: 767px) {
    padding: 30px 15px 20px;
  }
`;

const EventContainer = styled.div`
  @media (min-width: 450px) {
    float: left;
  }
`;

const TitleContainer = styled.div`
  float: left;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '30px 38px 30px 40px' };
  border-bottom: ${({ noBorder }) => !noBorder && '1px solid #e2e2e4' };
  margin: ${({ margin }) => margin};
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }

  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    width: 50%;
    >li{
      >a{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: visible !important;
      }
    }
  }
  @media (max-width: 1000px){
    width: 100%;
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TimezoneContainer = styled.div`
  float: left;
  width: 100%;
  padding: ${({ padding }) => padding ? padding : '30px 38px 30px 40px' };
  border-bottom: ${({ padding }) => padding ? 'unset' : '1px solid #e2e2e4'};
  
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .dropdown.btn-group {
    width: 100%;
    .dropdown-menu {
      width: 100%;
      height: 300px;
      overflow-y: auto;
    }
  }
  .searchable-dropdown {
    margin-top: ${({ padding }) => padding ? '0px' : '15px'};
  }
  .menu-item__control {
    height: 46px;
    background-color: #fff;
    border: 1px solid #cdcbcb;
    cursor: pointer;
  }

  @media (max-width: 550px) {
    padding: ${({ padding }) => padding ? padding : '30px 15px 30px' };
  }

  @media (max-width: 1000px){
    width: 100%;
  }
`;

const PageTitle = styled.div`
  font-size: 24px;
  letter-spacing: 1px;
  color: rgb(51,51,51);
  margin-bottom: 20px;
  display: inline-block;
  float: left;
  margin-top: 0;
  padding-left: 0;
`;

const FormContainer = styled.form`
  width: ${({ editChallenge }) => editChallenge ? '100%' : '65%'};
  float: left;
  display: block;
  background-color: white;
  box-shadow: 1px 1.732px 46px rgba(0,0,0,0.18);

  .calendar{
    display: flex;
    float: left;
  }
  
  > div > ul {
    margin-top: 20px;
  }
  
  @media only screen and (max-width: 767px) {
   width: 100%;
   margin-bottom: 20px;
   margin-top: 20px;
  }
  > h3 {
    font-size: 18px;
    padding: 32px 0 10px 35px;
    margin: 0;
    
    @media (max-width: 500px) {
      font-size: 20px;
      padding: 20px 10px 0 10px;
    }
  }
`;

const SaveButton = styled.button`
  background-color: ${(props) => props.color || '#ef6827'};
  border: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid #ef6827'};
  color: white;
  white-space: nowrap;
  padding: ${({ addMargin }) => addMargin ? '0' : '7px 10px'};
  width: ${({ addMargin }) => addMargin ? '158px' : 'auto'};
  height: ${({ addMargin }) => addMargin ? '35px' : 'auto'};
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  font-family: ${BoldFont};
  margin-top: 10px;
  margin-bottom: ${(props) => props.addMargin ? '55px' : '0'};
  float: ${(props) => props.float ? 'right' : 'left'};
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
  
  ${({ addMargin }) => addMargin && css`
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  `}
`;

const StyledInput = styled.input`
float: left;
margin-top: 10px;
border-radius: 4px;
background-color: #ffffff;
border: 1px solid #cdcbcb;
height: 50px;
font-size: ${({ locationInput }) => (locationInput ? '14px' : '16px')};
padding: 0 30px 0 20px;
color: rgb(153, 153, 153);
cursor: ${({ disabled }) => (disabled ? 'not-allowed !important' : 'pointer')};

&:focus, &:active {
  outline: none;
}

::placeholder {
  color: rgb(153, 153, 153);
  opacity: 1;
}

:-ms-input-placeholder {
  color: rgb(153, 153, 153);
}

::-ms-input-placeholder {
  color: rgb(153, 153, 153);
}
`;

const CustomCheckbox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: ${({ importantBottom }) => importantBottom ? '0px !important' : '10px'};
  margin-bottom: ${({ importantBottom }) => importantBottom ? '0px !important' : '10px'};
  margin-right: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(153,153,153);
  font-weight: normal;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  float: left;
  text-align: left;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;

    > input {
      cursor: not-allowed;
    }
  `}
  
  @media (max-width: 767px) {
    margin-right: 10px;
  }
  
  ${({ hidden }) => hidden && css`
    display: none;
  `}
  
  > span {
    color: black;
  }
`;

const CheckMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 8px;
    top: 2px;
    width: 6px;
    height: 13px;
    border-style: solid;
    border-color: ${({ checked }) => checked ? '#159fc9' : 'white'};
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;

const CustomTextArea = styled.textarea`
  font-size: 14px;
  padding: 18px 15px;
  border-radius: 4px;
  border: 1px solid #cdcbcb;
  float: right;
  width: 100%;
  margin-top: 16px;
  resize: none;
  color: rgb(153,153,153);
  cursor: ${({ disableArea }) => disableArea && 'not-allowed'};
  
  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: rgb(153,153,153);
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: rgb(153,153,153);
  }

  ::-ms-input-placeholder {
    color: rgb(153,153,153);
  }
`;

const ButtonContainer = styled.div`
  display: inline-block;
  float: right !important;
  width: auto !important;
  
  button {
    float: right;
    margin-top: 0;
    margin-left: 10px;

    @media (max-width: 430px) {
      width: calc(100% - 30px);
      float: left;
      margin: 0 15px 10px;
    }
  }
`;

const InviteContainer = styled.div`
  float: left;
  width: 100%;
  border-radius: 2px;
  background-color: ${({bgColor}) => bgColor ? bgColor : '#f3f3f3'};
  padding: 10px 5px 10px 15px;

  @media (max-width: 550px) {
    padding: 10px 5px 10px 10px;
  }
`;

const ScrollableContainer = styled.div`
  height: 280px;
  float: left;
  width: 100%;
  padding: 20px 5px 20px 0;
  overflow-y: auto;
  @media (max-width: 550px) {
    padding: 10px 5px 10px 0;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #403e3f;
    cursor: pointer;
    border-radius: 2.5px;
  }

  ::-webkit-scrollbar-track {
    background: #c3c2c2;
    border-radius: 2.5px;
  }
`;

const InviteAmigo = styled.div`
  float: left;
  width: ${({width})=> width ? width : '180px'};
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  display: ${({ hidden }) => hidden && 'none'}
  > img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    float: left;
    object-fit: cover;
  }
  
  label {
    padding-left: 10px;
    margin-top: -12px;
  }
  .showInfoText{
    display: none;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    position: absolute;
    width: 270px;
    background: white;
    border-radius: 6px;
    height: 80px;
    z-index: 9999;
    top: -68px;
    left: 40px;
    padding: 10px;
    font-family: Rubik-Regular;
    &::after {
      width: 10px;
      height: 10px;
      background: #fff;
      position: absolute;
      content: '';
      bottom: -4px;
      left: 6%;
      transform: rotate(-45deg);
    }
    @media(max-width: 400px) {
      width: 250px;
    }
  }
  &:hover{
    .showInfoText{
      display: block;
    }
  }
  @media only screen and (max-width: 767px) {
    
    > img {
      margin-left: 15px;
    }
  }
`;

const NameContainer = styled.div`
  display: inline-block;
  float: left;
  padding-left: 10px;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
`;

const ImageInput = styled.label`
  color: ${({ Color }) => Color ? Color : 'white'};
  padding: 9px 20px;
  background-color:${({ bgColor }) => bgColor ? bgColor : '#159fc9'};
  border-radius: 30px;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  line-height: ${({ lineHeight }) => lineHeight && '1.428571429'};
  margin-top: 10px;
  margin-top: ${({ margin }) => margin ? margin : '10px'};
  font-family: ${BoldFont};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  
  > input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    bottom: ${({imageReload}) => imageReload && '0'};
  }
  
  ${(props) => props.showAtBottom && css`
    position: absolute;
    left: calc(40% + 20px);
    bottom: 0;

    @media (max-width: 500px) {
      left: 0;
      position: static;
    }
  `}
`;

const ImageContentContainer = styled.div`
  display: inline-block;
  float: left;
  width: 60%;
  padding-left: 20px;
  margin-top: 10px;

  > div {
    font-size: 14px;
    letter-spacing: 0px;
    color: rgb(153,153,153);
    width: 100%;
    word-break: break-all;
  }
  
  @media (max-width: 500px) {
    width: 100%;
    padding-left: 0;
  }
`;

const InputContainer = styled.div`
  display: inline-block;
  width: ${({timeInput, widthInput, width}) => timeInput ? '100px' : widthInput ? '100%': width ? '100%' : '120px'} !important;
  margin-right: 10px;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop && marginTop };
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  >input{
    background: ${({ disabled }) => disabled ? '#e6e6e6' : '#ffffff'};
  }

  i {
    position: absolute;
    right: 10px;
    top: 17px;
    color: #999999;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  > div {
    width: ${({timeInput, widthInput, width}) => timeInput ? '100px' : widthInput ? '100%': width ? '100%' : '120px'} !important;
  }

  svg {
    top: 0;
    right: 0px;
    height: 42px;
    width: 15px;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
  .rdtPicker {
    width: 100% !important;
    min-width: ${({ event }) => event ? '150px !important' : '300px'};
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  }
`;

const StyledDateTime = styled(Datetime)`
  > input {
    padding: 10px;
    margin-top: 0;
    height: 48px;
    color: rgb(153,153,153);
    border: 1px solid #cdcbcb;
    position: relative;
    background-color:${({ disabled }) => disabled ? '#eee' :  'white'};
    font-size: 13px;
    cursor: pointer;
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    
    &:hover, &:focus, &:active {
      outline: none;
      border-color: #cdcbcb;
      box-shadow: none;
      background-color:${({ disabled }) => disabled ? '#eee' :  'white'};
    }
  }
  
  .rdt {
    >i {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    }
  }
  .rdtPicker {
    width: 300px;
  }
  .rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    cursor: pointer !important;
  }

  .rdtPicker td.rdtToday::before {
    bottom: 4px !important;
  }

  ${({ timeInput }) => timeInput && css`
    .rdtPicker {
      width: 150px;
    }
  `}
`;

const StartDateContainer = styled.div`
  width: auto;
  float: left;
  display: inline-block;
  
  @media (max-width: 1243px) and (min-width: 767px) {
    margin-top: 10px;
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
  }

  @media (max-width: 680px) {
    margin-top: 10px;
    width: 100%;
    &:first-child {
      margin-top: 0;
    }
  }

    ${({ isSurvivorChallenge }) => isSurvivorChallenge && css`
    width: auto !important;
    margin-top: 0 !important;
    @media (max-width: 640px) {
      width: 100% !important;
      margin-top: 10px !important;
      &:first-child {
        margin-top: 0 !important;
      }
    }
  `}
`;

const DateJoiner = styled.span`
  float: left;
  margin-right: 10px;
  margin-top: 15px;
  text-transform: uppercase;
  font-size: 14px;

  @media (max-width: 1243px) and (min-width: 767px) {
    display: block;
    text-align: center;
    min-width: 120px;
  }

  @media (max-width: 680px) {
    width: ${({ eventPage }) => eventPage ? '240px' : '120px'};
    display: block;
    text-align: center;
  }

  @media (max-width: 500px) {
    width: ${({ eventPage }) => eventPage ? '100%' : '120px'};
    display: block;
    text-align: center;
  }

  ${({ isSurvivorChallenge }) => isSurvivorChallenge && css`
    width: auto !important;
    min-width: auto !important;
    @media (max-width: 1243px) and (min-width: 767px) {
      display: block;
      text-align: center;
      width: auto !important;
    }
    @media (max-width: 640px) {
      width: ${({ eventPage }) => eventPage ? '100%' : '120px'}!important;
      display: block;
      text-align: center;
      min-width: 120px;
    }
  `}
`;

const CustomDropDown = styled(DropdownButton)`
  padding: 12px 10px;
  width: 50%;
  text-align: left;
  margin-top: 20px;
  color: rgb(153,153,153) !important;
  background-color: white !important;
  box-shadow: none !important;
  border-color: #ccc !important;
  > ul {
    max-width: 300px !important;
    overflow: hidden;
    overflex-x: hidden;
    overflow-y: auto;
  }
  > span {
    float: right;
    margin-top: 8px;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    color: rgb(153,153,153);
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100%;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
`;

const FieldTitle = styled.span`
  font-size: 14px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'rgb(51,51,51)'};
  font-family: ${BoldFont};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const ImageContainer = styled(TitleContainer)`
  padding: ${({ padding }) => padding ? padding : '25px 38px 25px 40px' };
  height: 100%;
  border-bottom: ${({ border }) => border ? '0' : '1px solid #e2e2e4' };
  > div {
    position: relative;
  }

  .dropdown-menu {
    width: 50%;
  }

  button#dropdown-basic {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
  }

  img {
    width: 40%;
    margin-top: 10px;
    float: left;
    min-height: 150px;
    
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    padding: 25px 15px 25px;
  }
`;

const AmigosContainer = styled(TitleContainer)`
  padding: 20px 40px 30px 40px;
  padding: ${({ padding }) => padding ? padding : '20px 40px 30px 40px' };
  border-bottom: ${({ noBorder }) => !noBorder && '1px solid #e2e2e4' };

  > div {
    margin-top: 0;
  }

  @media (max-width: 550px) {
    padding: 20px 15px 20px;
  }
`;

const TopContainer = styled.div`
  padding-bottom: 16px;
  float: left;
  width: 100%;
`;

const PlacesContainer = styled.div`
  float: left;
  width: 100%;
  height: auto;
  max-height: 200px;
  border: 1px solid #ededed;
  overflow-y: auto;

  > div {
    float: left;
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-bottom: 1px solid #ededed;
    cursor: pointer;
  }
`;

const DateIconContainer = styled.div`
  float: left;
  position: absolute;
  top: 12px;
  right: 10px;
  width: 24px;
  cursor: pointer;
  line-height: 0px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  div{

    >img{
    width: 24px;
    height: 24px;
    margin-top: 0px;
    min-height:24px;
  }

  }
  >img{
    width: 24px;
    height: 24px;
    margin-top: 0px;
    min-height:24px;
  }
  > svg {
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    top: ${({ noTop }) => noTop && '0px'};
    width:24px !important;
    height:auto !important;
  }
  .rdt {
    .far fa-clock {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'}; 
    }
  }
`;

const RemovePhotoButton = styled(SaveButton)`
  width: auto;
  height: auto;
  padding: 9px 20px;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
`;

const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: ${({minHeight}) => minHeight ? '180px' : '0px'};
  font-size: 14px;
`;

const StyledInputNew = styled(CustomInput)`
  float: left;
  margin-top: 0px;
  border-radius: 4px;
  background-color: ${({ disabled}) => disabled ? '#e6e6e6' : '#ffffff'};
  border: 1px solid #9C9C9C;
  height: 50px;
  font-size: ${({ locationInput }) => locationInput ? '14px' : '16px'};
  padding: 0 15px;
  color: #9c9c9c;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  width: 100%;
  font-family: 'rubik';

  &:focus, &:active {
    outline: none;
  }

  ::placeholder {
    color: #9c9c9c;
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: #9c9c9c;
  }

  ::-ms-input-placeholder {
    color: #9c9c9c;
  }
`;


const SaveButtonV2 = styled.button`
  background-color: ${(props) => props.color || 'rgb(253, 113, 117)'};
  border: ${(props) => props.color ? `1px solid ${props.color}` : '1px solid rgb(253, 113, 117)'};
  color: white;
  white-space: nowrap;
  padding: ${({ addMargin }) => addMargin ? '0' : '7px 10px'};
  width: ${({ addMargin }) => addMargin ? '385px' : '385px'};
  height: ${({ addMargin }) => addMargin ? '50px' : '50px'};
  font-size: 18px;
  line-height: 1.428571429;
  border-radius: 6px;
  font-family: 'Rubik-Medium';
  margin-top: 10px;
  margin-bottom: ${(props) => props.addMargin ? '55px' : '0'};
  float: ${(props) => props.float ? 'right' : 'left'};
  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }

  ${({ disabled }) => disabled === true && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 15px;
  }
  
  ${({ addMargin }) => addMargin && css`
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  `}
`;

const StyledDateTimeV2 = styled(Datetime)`

  > input {
    padding: 10px;
    margin-top: 0;
    height: 48px;
    color: #0D4270;
    border: 1px solid rgba(156, 156, 156, 1);
    position: relative;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    font-family:"Rubik";
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    background: rgba(156,156,156,0.05);
    &:hover, &:focus, &:active {
      outline: none;
      border: 1px solid rgba(156, 156, 156, 1);
      box-shadow: none;
    }
  }
  
  .rdt {
    >i {
      cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
    }
  }
  .rdtPicker {
    width: 300px;
  }
  .rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    cursor: pointer !important;
  }

  .rdtPicker td.rdtToday::before {
    bottom: 4px !important;
  }

  ${({ timeInput }) => timeInput && css`
    .rdtPicker {
      width: 150px;
    }
  `}
  .form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control{
    background: rgba(156,156,156,0.05);
  }
`;

export { Layout, PageTitle, FormContainer, SaveButton, StyledInput, CustomCheckbox, CheckMark, CustomTextArea,
  ButtonContainer, InviteContainer, InviteAmigo, NameContainer, ImageInput, ImageContentContainer, InputContainer, StartDateContainer,
  DateJoiner, CustomDropDown, CustomMenuItem, StyledDateTime, TitleContainer, FieldTitle, ImageContainer, AmigosContainer, TopContainer,
  ScrollableContainer, PlacesContainer, DateIconContainer, RemovePhotoButton, LocationContainer, TimezoneContainer, EventContainer, StyledInputNew, SaveButtonV2, StyledDateTimeV2};
