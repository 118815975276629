/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepsWrapper, Steps } from './style';
import { withTranslation } from 'react-i18next';

class StepsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      stepsSurvey:[
        {
          name: 'About The Survey',
          showArrow: true,
          number: 1
        },
        {
          name: 'Questions to Ask',
          showArrow: false,
          number: 2
        },
        {
          name: 'Launch a Survey',
          showArrow: false,
          number: 3
        }
      ]

    }
  }

  stepsHeadingEvent = [
    {
      name: 'About Event',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Event',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Event',
      showArrow: false,
      number: 4
    }
  ];

  stepsHeadingOndemand = [
    {
      name: 'About Event',
      showArrow: true,
      number: 1
    },
    {
      name: 'Create Event',
      showArrow: false,
      number: 2
    }
  ];

  stepsFitnessExercise = [
    {
      name: 'About The Exercise',
      showArrow: true,
      number: 1
    },
    {
      name: 'Set Exercise Focus area and age Groups',
      showArrow: false,
      number: 2
    }
  ];

  stepsFitnessWorkout = [
    {
      name: 'About The Workout',
      showArrow: true,
      number: 1
    },
    {
      name: 'Add Exercise',
      showArrow: false,
      number: 2
    }
  ];

  stepsLaunchCompanyInitiative = [
    {
      name: 'About Initiative',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Initiative',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Initiative',
      showArrow: false,
      number: 4
    }
  ];

  stepsHeadingHealthProgram = [
    {
      name: 'About Program',
      showArrow: true,
      number: 1
    },
    {
      name: 'Create Program',
      showArrow: false,
      number: 2
    }
  ];

  stepsHeadingCoreValue = [
    {
      name: 'About Core Value',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Core Value',
      showArrow: false,
      number: 2
    }
  ];

  surveyLibrary = [
    {
      name: 'About Survey',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Survey',
      showArrow: false,
      number: 2
    }
  ];

  stepsSurvey=[
    {
      name: 'About Survey',
      showArrow: true,
      number: 1
    },
    {
      name: 'Customize Survey',
      showArrow: true,
      number: 2
    },
    {
      name: 'Invite Users',
      showArrow: true,
      number: 3
    },
    {
      name: 'Launch Survey',
      showArrow: false,
      number: 4
    }
  ]

  // componentDidMount(){
  //   if(this.props.type === "survey" && this.props.isLaunch){
  //     this.setState({stepsSurvey:[
  //       {
  //         name: 'About The Survey',
  //         showArrow: true,
  //         number: 1
  //       },
  //       {
  //         name: 'Questions to Ask',
  //         showArrow: true,
  //         number: 2
  //       },
  //       {
  //         name: 'Launch a Survey',
  //         showArrow: false,
  //         number: 3
  //       }
  //     ]})
  //   }
  
  // }

  render() {
    const { stepCount,type, padding    } = this.props;
    const heading = type==="core-value"? this.stepsHeadingCoreValue: type==="event" ? this.stepsHeadingEvent: type==="exercise" ? this.stepsFitnessExercise : type==="workout" ? this.stepsFitnessWorkout : type==="initiative" ? this.stepsLaunchCompanyInitiative : type==="health-program" ? this.stepsHeadingHealthProgram : type==="survey-library" ? this.surveyLibrary :  type==="survey" ? this.stepsSurvey  : type === "create-coach" ? [{
      name: 'About Coach',
      showArrow: true,
      number: 1
    },{
      name: 'Create Coach',
      showArrow: false,
      number: 2
    }] : this.stepsHeadingOndemand;
    return (
      <StepsWrapper padding={padding?padding:0}>
        {heading.map((step, index) =>
          (<Steps key={index} active={index+1<=stepCount?true:false}>
            <div className="wrapper">
              <div className="number" >{step.number}</div>
              <div className="heading"style={{color: index+1<=stepCount ? "#005C87" : "rgba(0, 92, 135, 0.60)",fontFamily: index+1<=stepCount ? "Rubik-Medium" : "Rubik-Regular",fontSize: "18px",fontWeight: 500,lineHeight: "24px" }}>{this.props.t(step.name)}</div>
            </div>
            {step.showArrow && <div className="arrow">
              <div className="active">
                {index+1<=stepCount?
                  <img src={"/public/images/stepArrowUpdated.svg"} />:
                  <img src={"/public/images/stepArrowUpdated.svg"} />}
              </div>
            </div>}
          </Steps>))}
      </StepsWrapper>
    );
  }
}

StepsHeading.propTypes = {
  history: PropTypes.object,
  stepCount: PropTypes.number,
  type: PropTypes.string,
  isLaunch :PropTypes.bool,
  t: PropTypes.func,
  padding: PropTypes.string
};

export default ((withTranslation())(StepsHeading));